const tableDataCourses = [
  {
    id: 1,
    course: 'Recruiting Mantotalname',
    total_messages: 1,
    authors: [
      {
        id: 1,
        name: 'Klebinho',
      },
    ],
  }, {
    id: 2,
    course: 'Quality Engineer',
    total_messages: 12,
    authors: [
      {
        id: 2,
        name: 'Cleitinho',
      },
      {
        id: 3,
        name: 'Claudinha',
      },
    ],
  }, 
]

export { tableDataCourses };
