<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DonutChart from "../../charts/chartjs/donut-chart.vue"

import { tableDataCourses } from "./data-advancedtable-courses";
import { tableDataMessages } from "./data-advancedtable-messages";

/**
 * Students Doubts component
 */
export default {
  page: {
    title: "Mensagens",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    DonutChart
  },
  data() {
    return {
      tableDataCourses: tableDataCourses,
      tableDataMessages: tableDataMessages,
      title: "Mensagens",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Mensagens",
          active: true,
        },
      ],
      tableHighlighted: 0,
      totalRowsCourses: 1,
      totalRowsMessages: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        course: "Curso",
        totalReviews: "Total de Revisões",
        actions: "Ações",
      },
      fieldAliasesMessages: {
        id: "#",
        course: "Curso",
        topic: "Tópico",
        totalMessages: "Total de Mensagens",
        status: "Status",
        actions: "Ações",
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rowsCourses() {
      return this.tableDataCourses.length;
    },
    rowsMessages() {
      return this.tableDataMessages.length;
    },
    fieldsCourses() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "10%" }, },
        { key: "course", sortable: true, label: this.fieldAliases.course },
        { key: "total_messages", sortable: true, label: this.fieldAliases.totalMessages, thStyle: { width: "30%" }, },
        { key: "authors", sortable: true, label: this.fieldAliases.authors },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" }, },
      ];
    },
    fieldsMessages() {
      return [
        { key: "id", sortable: true, label: this.fieldAliasesMessages.id, thStyle: { width: "10%" }, },
        { key: "course", sortable: true, label: this.fieldAliasesMessages.course },
        { key: "topic", sortable: true, label: this.fieldAliasesMessages.topic, thStyle: { width: "30%" }, },
        { key: "total_messages", sortable: true, label: this.fieldAliasesMessages.totalMessages, thStyle: { width: "30%" }, },
        { key: "status", sortable: true, label: this.fieldAliasesMessages.status, thStyle: { width: "10%" }, },
        { key: "actions", sortable: true, label: this.fieldAliasesMessages.actions, thStyle: { width: "110px" }, },
      ];
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRowsCourses = this.items.length;
    this.totalRowsMessages = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFilteredCourses(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsCourses = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredMessages(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsMessages = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
      :addText="'Adicionar Nova Avaliação'"
      :enableCad="false"
    />
    <div class="row">
      <div class="col-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Total de Mensagens</h4>
            <div class="row justify-content-center">
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">9595</h5>
                  <p class="text-muted">Activated</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">36524</h5>
                  <p class="text-muted">Pending</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">62541</h5>
                  <p class="text-muted">Deactivated</p>
                </div>
              </div>
            </div>
            <!-- Donut Chart -->
            <DonutChart :height="240" />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Não Respondidas</h4>
            <div class="row justify-content-center">
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">36524</h5>
                  <p class="text-muted">Pending</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">62541</h5>
                  <p class="text-muted">Deactivated</p>
                </div>
              </div>
            </div>
            <!-- Donut Chart -->
            <DonutChart :height="240" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-5">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Cursos</h4>
                <p class="card-title-desc"></p>
                <div class="row mb-md-2">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Mostrar
                        <b-form-select
                          class="form-control form-control-sm form-select form-select-sm"
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                        Registros
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-end"
                    >
                      <label class="d-inline-flex align-items-center">
                        Pesquisar:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Digite algo..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0 datatables">
                  <b-table
                    :items="tableDataCourses"
                    :fields="fieldsCourses"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFilteredCourses"
                  >
                    <template #cell(status)="obj">
                      <div class="actions-cell">
                        <span
                          class="badge ms-1"
                          :class="
                            obj.item.status == 'A' ? 'bg-success' : 'bg-warning'
                          "
                        >
                          <h6 class="m-0">
                            {{ obj.item.status == "A" ? "Ativo" : "Inativo" }}
                          </h6>
                        </span>
                      </div>
                    </template>
                    <!-- Coluna "authors" personalizada -->
                    <template #cell(authors)="course">
                      <div class="actions-cell">
                        <ul>
                          <li v-for="author of course.item.authors" :key="author.id">
                            {{ author.name }}
                          </li>
                        </ul>
                      </div>
                    </template>
                    <!-- Coluna "actions" personalizada -->
                    <template #cell(actions)="category">
                      <div class="actions-cell">
                        <b-button
                          class="w-100"
                          pill
                          @click="deleteItem(category.item)"
                          variant="outline-primary"
                        >
                          <i class="mdi mdi-dots-vertical"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rowsCourses"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <div class="col-12 col-md-7">
         <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Mensagens</h4>
                <p class="card-title-desc"></p>
                <div class="row mb-md-2">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Mostrar
                        <b-form-select
                          class="form-control form-control-sm form-select form-select-sm"
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                        Registros
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-end"
                    >
                      <label class="d-inline-flex align-items-center">
                        Pesquisar:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Digite algo..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0 datatables">
                  <b-table
                    :items="tableDataMessages"
                    :fields="fieldsMessages"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFilteredMessages"
                  >
                    <template #cell(status)="obj">
                      <div class="actions-cell">
                        <span
                          class="badge ms-1"
                          :class="
                            obj.item.status == 'A' ? 'bg-success' : 'bg-warning'
                          "
                        >
                          <h6 class="m-0">
                            {{ obj.item.status == "A" ? "Aberto" : "Fechado" }}
                          </h6>
                        </span>
                      </div>
                    </template>
                    <!-- Coluna "actions" personalizada -->
                    <template #cell(actions)="category">
                      <div class="actions-cell">
                        <b-button
                          class="w-100"
                          pill
                          @click="deleteItem(category.item)"
                          variant="outline-primary"
                        >
                          <i class="mdi mdi-dots-vertical"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rowsMessages"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>
  </Layout>
</template>
