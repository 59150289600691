const tableDataMessages = [
  {
    id: 1,
    course: 'Recruiting Mantotalname',
    topic: 'Quality Engineer',
    total_messages: 1,
    status: 'I',
  }, {
    id: 2,
    course: 'Quality Engineer',
    topic: 'Quality Engineer',
    total_messages: 12,
    status: 'A',
  }, {
    id: 3,
    course: 'Senior Editor',
    topic: 'Quality Engineer',
    total_messages: 33,
    status: 'A',
  }, {
    id: 4,
    course: 'Programmer II',
    topic: 'Quality Engineer',
    total_messages: 41,
    status: 'I',
  },
  {
    id: 5,
    course: 'Data Coordiator',
    topic: 'Quality Engineer',
    total_messages: 2,
    status:  'A',
  }, {
    id: 6,
    course: 'Data Coordiator',
    topic: 'Quality Engineer',
    total_messages: 12,
    status: 'I',
  },
]

export { tableDataMessages };
